import React, { useState } from 'react';
import './cardStyles.css';

const Card = ({ title, content }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCardClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="card">
      <div className="card-title">{title}</div>
      <div className="card-content" style={{ opacity: isCollapsed ? 0 : 1 }}>
        {content}
      </div>
    </div>
  );
};

export default Card;
