import React from 'react';
import './About.css';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="about-container">
    <Helmet>
      <title>About YouTube Rabbithole</title>
      <meta name="description" content="Learn about YouTube Rabbithole, your ultimate destination for exploring YouTube content." />
    </Helmet>
      <h1 className="title">About YouTube Rabbithole</h1>
      <p className="text">
        Welcome to <span className="highlight">YouTube Rabbithole</span>, your ultimate destination for learning and exploring YouTube content like never before. Our app allows you to paste any YouTube link and instantly get a detailed summary and transcript of the video.
      </p>
      <p className="text">
        With <span className="highlight">YouTube Rabbithole</span>, you can dive deeper into topics by clicking on the `Get Related Videos` button, which shows you related YouTube videos. Our recursive summarization feature enables continuous learning, making it easy to explore complex subjects thoroughly.
      </p>
      <p className="text">
        Whether you're a student, a professional, or just a curious mind, <span className="highlight">YouTube Rabbithole</span> is designed to help you get the most out of YouTube. Join us and start your journey down the rabbit hole of knowledge today!
      </p>
    </div>
  );
};

export default About;
