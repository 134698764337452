import React from 'react';
import './YoutubeEmbedder.css'
import { Button } from '@mui/material';

const YouTubeVideoEmbedder = ({ summarizeVideo, videoUrls }) => {

  const createVideoIframe = (url) => {
    let videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }

    return (
      <div className='video-container'>
      <iframe
        key={videoId}
        width="450"
        height="350"
        style={{borderRadius:"2%"}}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={videoId}
      ></iframe>
      <div className='summarize-button'>
       <Button variant='outlined' onClick={()=> handleSubmit(videoId)} >Summarize</Button>
       </div>
       </div>
    );
  };

  const handleSubmit = (videoId) => {
    // const { name, value } = e.target;
    console.log("click event" , videoId);
    summarizeVideo(videoId);

  }

  
  return (
    <div className="videos-container">
      {videoUrls && videoUrls.map((url) => createVideoIframe(url))}
    </div>
  );
};

export default YouTubeVideoEmbedder;