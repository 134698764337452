import Button from '@mui/material/Button';
import '../App.css';
import ShinyText from '../ShinyText';

function WithoutSummary({ AppName, handleSubmit, url, handleInputChange, summary }) {
    return (
        <div className='without-summary-submit-module'>
            <h1>{AppName}</h1>
            <h2>Transform your YouTube videos into quick, insightful summaries 📺✨</h2>
            <input
              className='submit-text'
                type="text"
                placeholder="Enter a YouTube URL..."
                value={url}
                onChange={handleInputChange}
            />
            <Button variant='contained' color='youtubeRed' onClick={handleSubmit}>
                Submit
            </Button>
            {summary === '' && <div className='shiny-text'>
                <ShinyText text="Summarize any YouTube video for free!" />
            </div>}
        </div>
    );
}

function WithSummary({ AppName, handleSubmit, url, handleInputChange, summary }) {
    return (
        <div className='with-summary-submit-module'>
            <h1>{AppName}</h1>
            <h2>Transform your YouTube videos into quick, insightful summaries 📺✨</h2>
            <input
                className='submit-text'
                type="text"
                placeholder="Enter a YouTube URL..."
                value={url}
                onChange={handleInputChange}
            />
            <Button variant='contained' color='youtubeRed' onClick={handleSubmit}>
                Submit
            </Button>
            {summary === '' && <div className='shiny-text'>
                <ShinyText text="Summarize any YouTube video for free!" />
            </div>}
        </div>
    );
}

const SubmitModule = ({ AppName, handleInputChange, handleSubmit, url, videoUrls, summary }) => {
    return (
        <div>
            {summary === ''
                ? <WithoutSummary AppName={AppName} handleSubmit={handleSubmit} url={url} handleInputChange={handleInputChange} summary={summary} />
                : <WithSummary AppName={AppName} handleSubmit={handleSubmit} url={url} handleInputChange={handleInputChange} summary={summary} />
            }
        </div>
    );
}


export default SubmitModule;