import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Signup.css';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const API_ENDPOINT = 'https://iobhmi3ah9.execute-api.us-east-1.amazonaws.com/Alpha';


  const handleSignUp = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    // Add sign-up logic here
    fetchSignup(username, password);
    console.log('username:', username,  'email', email,'Password:', password, 'Confirm Password:', confirmPassword);
    setError('');
  };

  const handleLoginRedirect = () => {
    navigate('/login'); // Adjust the path as necessary
  };

  const handleSuccessfulSignupRedirect = () => {
    navigate('/login'); // Adjust the path as necessary
  };

  const fetchSignup = async (username, password) => {
    try {
      const response = await fetch(`${API_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        
        body: JSON.stringify({
          path: 'signup',
          username: username,
          email: email,
          password: password,
        }),
      });
  
      const result = await response.json();
      if (result.statusCode == 200) {
        console.log('Signup successful:', result);
        handleSuccessfulSignupRedirect();
        // Handle successful signup (e.g., redirect to login page)
      } else {
        const responseBody = JSON.parse(result.body)

        console.error('Signup error:', result.error);
        console.log(responseBody)
        // Handle signup error (e.g., show error message)
        setError(responseBody.error)
      }
    } catch (error) {
      console.error('Error signing up:', error);
      // Handle network error (e.g., show error message)
      setError('Oops..looks like something went wrong. Can you please try after some time?')
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSignUp} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
          className='text-field-signup'
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
           <TextField
          className='text-field-signup'
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!error}
            helperText={error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Box textAlign="center">
            <Typography variant="body2" color="textSecondary">
              Already have an account?
            </Typography>
            <Button onClick={handleLoginRedirect} color="primary">
              Log in
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;
