import '../App.css';
import Card from '../Card';
import YouTubeVideoEmbedder from '../YoutubeEmbedder';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation

const ResultModule = ({ videoUrls, currentVideo, summary, fetchRelatedVideosData, summarizeVideo, isRelatedVideosLoading, transcript, isUserLoggedIn }) => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login'); 
  };

  return (
    <div className='result-module'>
      <div className='summary-module'>
        {videoUrls.length > 0 && currentVideo()}
        {isUserLoggedIn ? (
          <Card title="Summary" content={summary} />
        ) : (
          <Card title="Summary" content={
            <div style={{ textAlign: 'center' }}>
              <p style={{ fontWeight: 'bold', color: '#eb6576' }}>
                Please   <Button  className='button-login' variant='outlined' color='anger' onClick={handleLoginRedirect}>
                log in
              </Button> to view the summary 😊
              </p>
            </div>
          } />
        )}
        <Card title="Transcript" content={transcript} />
      </div>
      <div className='related-videos-module'>
        {videoUrls.length === 0 && currentVideo()}
        {videoUrls.length === 0 && (
          isUserLoggedIn ? (
            <div className='related-videos-button'>
              <Button variant='outlined' color='youtubeRed' onClick={fetchRelatedVideosData}>Get Related Videos</Button>
            </div>
          ) : (
            <div className='related-videos-button'>
              <Button variant='outlined' color='youtubeRed' disabled>Get Related Videos</Button>
              <p style={{ fontWeight: 'bold', color: '#eb6576' }}>
                Please   <Button  className='button-login' variant='outlined' color='anger' onClick={handleLoginRedirect}>
                log in
              </Button> to view related videos 😊
              </p>
            </div>
          )
        )}
        <div>
          {isRelatedVideosLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <div>
              <YouTubeVideoEmbedder videoUrls={videoUrls} summarizeVideo={summarizeVideo} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultModule;
