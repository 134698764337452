// src/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> [2024-01-06]</p>
            
            <h2>Introduction</h2>
            <p>Welcome to YouTube Rabbithole ("we", "our", "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we handle your information when you use our React app ("App"), which is designed to provide summaries and transcripts of YouTube videos.</p>
            
            <h2>Information We Collect</h2>
            <p>Our App does not collect, store, or process any personal information from users. We do not require you to provide any personal data to use our App.</p>
            
            <h2>Third-Party Services</h2>
            <p>Our App integrates with YouTube to provide video summaries and transcripts. While using our App, you may interact with YouTube's services, which are governed by YouTube's Privacy Policy. We recommend reviewing YouTube's Privacy Policy to understand how your data may be handled by YouTube.</p>
            
            <h2>Cookies and Tracking Technologies</h2>
            <p>Our App does not use cookies or any other tracking technologies to collect information about your usage.</p>
            
            <h2>Data Security</h2>
            <p>Since we do not collect any personal information, we do not store any data that could be subject to security breaches. However, we recommend that you use secure and trusted devices when accessing our App.</p>
            
            <h2>Your Rights Under GDPR</h2>
            <p>As our App does not process any personal data, the rights provided under the General Data Protection Regulation (GDPR) are not applicable. However, if you interact with YouTube through our App, you may exercise your GDPR rights directly with YouTube.</p>
            
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at [sassyrabbithole.@gmail.com].</p>
        </div>
    );
};

export default PrivacyPolicy;
