import About from "./About";
import App from "./App"
import PrivacyPolicy from "./PrivacyPolicy"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from "./Signup/Signup";
import Login from "./Login/Login";

export const Main = () => {

    return (
        <div>
              <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<About />} />
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        </Router>
        </div>
    )
}