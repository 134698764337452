import React, { useState, useEffect } from 'react';
import './App.css';
import Button from '@mui/material/Button';
import YouTubeVideoEmbedder from './YoutubeEmbedder';
import Card from './Card';
import './footer.css';
import {
  createTheme,
  PaletteColorOptions,
  ThemeProvider,
} from '@mui/material/styles';
import logo from './youtube-rabbithole-2.webp';
import GuidedPath from './GuidedPath';
import PrivacyPolicy from './PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SubmitModule from './SubmitModule/SubmitModule';
import { Helmet } from 'react-helmet';
import ResultModule from './ResultModule/ResultModule';
import Cookies from 'js-cookie';

function App() {
  const API_ENDPOINT = 'https://iobhmi3ah9.execute-api.us-east-1.amazonaws.com/Alpha';
  const YOUTUBE_URL = 'https://youtube.com/watch?v=';
  const APP_NAME = 'YouTube Rabbithole 🥕';

  const [url, setUrl] = useState('');
  const [summary, setSummary] = useState('');
  const [transcript, setTranscript] = useState('');
  const [keywords, setKeywords] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isRelatedVideosLoading, setRelatedVideosLoading] = useState(false);

  const [videoUrls, setVideoUrls] = useState([]);

  const [isVideoTooLong, setIsVideoTooLong] = useState(false);

  const [error, setError] = useState('');


  const validateYouTubeUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}(\S*)$/;
    console.log("testing url " , url);
    return regex.test(url);
  };

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUser = Cookies.get('username');
    if (loggedInUser) {
      setIsUserLoggedIn(true);
      setUsername(loggedInUser);
    }
  }, []);

  const handleLogout = () => {
    Cookies.remove('username');
    setIsUserLoggedIn(false);
    setUsername('');
    navigate('/');
  };

  const handleLogin = () => {
    navigate('/login');
  }
  const steps = [
    {
      title: 'Introduction',
      description: 'Learn the basics of the topic.',
    },
    {
      title: 'Intermediate Concepts',
      description: 'Dive deeper into the subject matter.',
    },
    {
      title: 'Advanced Techniques',
      description: 'Master the advanced aspects and techniques.',
    },
  ];

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      anger: createColor('#F40B27'),
      apple: createColor('#5DBA40'),
      steelBlue: createColor('#5C76B7'),
      violet: createColor('#BC00A3'),
      wormholeRed: createColor('#ef4d80'),
      youtubeRed: createColor('#e42c2c'),
    },
  });

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  function getYouTubeVideoID(url) {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const getVideoInformation = (url) => {
    console.log('re-summarizing video in app : ', url);
    setUrl(url);
    fetchSummaryData(url);
  };

  const handleSubmit = () => {
    console.log('Submitted URL:', url);

    if(validateYouTubeUrl(url)) {
    fetchSummaryData(url)
    setError('');
    }else {
      setError('Please enter a valid YouTube URL.');
      console.log('URL is invalid:', url);
    }
  };

  const summarizeVideo = (videoId) => {
    getVideoInformation(YOUTUBE_URL + videoId);
  };

  const fetchRelatedVideosData = async () => {
    try {
      console.log('getting data : ', keywords);
      setRelatedVideosLoading(true);
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ keywords: keywords, path: 'get_related_videos' }),
      });

      const result = await response.json();
      setRelatedVideosLoading(false);

      console.log('Data:', result);
      const body = JSON.parse(result.body);

      console.log('Body : ', body);
      parseUrls(body);
    } catch (error) {
      console.error('Error fetching data:', error);
      setRelatedVideosLoading(false);
    }
  };

  const fetchSummaryData = async (currentUrl) => {
    try {
      console.log('getting data : ', currentUrl);
      setIsVideoTooLong(false);
      setLoading(true);
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ video_url: currentUrl, path: 'get_summary' }),
      });

      const result = await response.json();

      if (response.ok) {
        setLoading(false);

        setRelatedVideosLoading(false);
        console.log('Data:', result);
        const body = JSON.parse(result.body);

        console.log('Body : ', body);
        setSummary(body.summary);
        setTranscript(body.transcript);
        setKeywords(body.keywords);
        setVideoUrls([]);
        // parseUrls(body)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      setRelatedVideosLoading(false);
      setIsVideoTooLong(true);
    }
  };

  const parseUrls = (body) => {
    const urls = [];
    const relatedVideos = body['related_videos'];
    console.log('realted videos : ', relatedVideos);
    for (let i = 0; i < relatedVideos.length; i++) {
      const relatedVideo = relatedVideos[i];
      console.log('realted video : ', relatedVideo);

      const videoId = relatedVideo[0]['videoId'];
      const finalUrl = YOUTUBE_URL + videoId;
      if (finalUrl != url) urls.push(YOUTUBE_URL + videoId);
    }

    console.log('urls:: ', urls);
    setVideoUrls(urls);
    // setVideoUrls(['https://www.youtube.com/watch?v=5NMxiOGL39M'])
  };

  function currentVideo() {
    return (
      <div>
        <iframe
          key={getYouTubeVideoID(url)}
          width="600"
          height="400"
          src={`https://www.youtube.com/embed/${getYouTubeVideoID(url)}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={getYouTubeVideoID(url)}
        ></iframe>
      </div>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <title>YouTube Rabbithole</title>
        <meta name="description" content="Explore and learn with ease by summarizing YouTube videos and accessing their transcripts. Dive deep into any topic with our intuitive keyword search and recursive summary feature. Perfect for students, educators, and lifelong learners!" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <header className="navbar">
          <img src={logo} alt="YouTube Rabbithole Logo" className="logo" />
          <nav className="nav-bar">
            <ul className="nav-links">
              <li><a href="#home">Home</a></li>
              <li><a href="/about">About</a></li>
              {!isUserLoggedIn?(<li style={{color:'red'}}><a href="/login">Login</a></li>) : (<li style={{color:'red'}}><a href="/home" onClick={handleLogout}>Logout</a></li>)}
            </ul>
           {isUserLoggedIn && (
              <>
               <span className='welcome'>Welcome,</span> <span className="username"> {username}!</span>
              
              </>
            )}
          </nav>
        </header>
        {/* <header className="App-header"></header> */}
        <SubmitModule AppName={APP_NAME} url={url} handleInputChange={handleInputChange} handleSubmit={handleSubmit} videoUrls={videoUrls} summary={summary} />
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : (
          !isVideoTooLong && summary && (
            <ResultModule
              videoUrls={videoUrls}
              currentVideo={currentVideo}
              summary={summary}
              fetchRelatedVideosData={fetchRelatedVideosData}
              summarizeVideo={summarizeVideo}
              isRelatedVideosLoading={isRelatedVideosLoading}
              transcript={transcript}
              isUserLoggedIn={isUserLoggedIn}
            />
          )
        )}
         {error && <div style={{ color: 'red', fontWeight:600 }}>{error}</div>}
        {isVideoTooLong && <h2>Oops..looks like your video was too long! Maybe try a shorter one 😅</h2>}
      </ThemeProvider>
    </div>
  );
}

export default App;
